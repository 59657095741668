import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ClientService } from "src/app/services/client.service";
import { SharedService } from "src/app/services/shared.service";
import { FormDataService } from "../new-order/data/form-data.service";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit, OnDestroy {
  locationsList: Array<any> = new Array<any>();
  locationSelected: any;

  isLoading: boolean = true;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formDataService: FormDataService,
    private clientService: ClientService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    if (localStorage.getItem("location")) {
      this.router.navigate(["/admin/orders"]);
    }
    this.isLoading = true;
    this.clientService
      .getLocations()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.locationsList = res;
          this.locationSelected = 0;
          this.isLoading = false;
        },
        () => (this.isLoading = false)
      );
  }

  locationChange(locationId: string) {}

  locationSubmit() {
    this.sharedService.setLocationUrl(
      this.locationsList[
        this.locationsList.findIndex((l) => l.id === this.locationSelected)
      ]
    );
    localStorage.setItem(
      "location",
      JSON.stringify(
        this.locationsList[
          this.locationsList.findIndex((l) => l.id === this.locationSelected)
        ]
      )
    );
    this.router.navigate(["/admin/orders"]);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
