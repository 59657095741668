import {Injectable} from '@angular/core';
import {FormDataService} from "../data/form-data.service";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class StepTwoGuardService {

  constructor(
    private formData: FormDataService,
    private router: Router,
    private _route: ActivatedRoute,
  ) {
  }

  canActivate(): boolean {
    if (!this.formData.activeSteps['step_2']) {
      this.router.navigate(['/admin/new-order/calendar']);
      return false;
    }
    return true;
  }
}
