import {Component, OnInit} from '@angular/core';
import {FormDataService} from "../data/form-data.service";

@Component({
  selector: 'app-tabs-links',
  templateUrl: './tabs-links.component.html',
  styleUrls: ['./tabs-links.component.scss']
})
export class TabsLinksComponent implements OnInit {

  activeSteps: any;

  constructor(private formDataService: FormDataService,) {
  }

  ngOnInit() {
    this.activeSteps = this.formDataService.getActiveStep();
  }

}
