import { BrowserModule } from "@angular/platform-browser";
import { Injectable, LOCALE_ID, NgModule } from "@angular/core";
import { Router, RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AngularTokenModule, AngularTokenService } from "angular-token";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { AppComponent } from "./app.component";

//Register Local Currency Types in 'IT'
import it from "@angular/common/locales/it";
import { registerLocaleData } from "@angular/common";
registerLocaleData(it);

import { AdminComponent } from "./admin/admin.component";
import { LoginComponent } from "./login/login.component";
import { HeaderComponent } from "./header/header.component";
import { NotifierModule } from "angular-notifier";
import { NewOrderComponent } from "./admin/new-order/new-order.component";
import { TabDateComponent } from "./admin/new-order/tab-date/tab-date.component";
import { OrdersComponent } from "./admin/orders/orders.component";
import { TabTypeComponent } from "./admin/new-order/tab-type/tab-type.component";
import { MyDatePickerModule } from "./admin/orders/my-date-picker";
import { TabsLinksComponent } from "./admin/new-order/tabs-links/tabs-links.component";
import { StepTwoGuardService } from "./admin/new-order/guards/step-two-guard.service";
import { StepThreeGuardService } from "./admin/new-order/guards/step-three-guard.service";
import { StepFourGuardService } from "./admin/new-order/guards/step-four-guard.service";
import { CheckoutGuardService } from "./admin/new-order/guards/checkout-guard.service";
import { FormDataService } from "./admin/new-order/data/form-data.service";
import { TabTicketsComponent } from "./admin/new-order/tab-tickets/tab-tickets.component";
import { TabPaymentComponent } from "./admin/new-order/tab-payment/tab-payment.component";
import { CheckoutComponent } from "./admin/new-order/checkout/checkout.component";
import { environment } from "src/environments/environment";
import { LocationsComponent } from "./admin/locations/locations.component";
import {Observable, of} from 'rxjs';
import {AuthService} from "./services/auth.service";
import { catchError } from "rxjs/operators";
import {NgxPaginationModule} from 'ngx-pagination';
import { OpenRowDirective } from "./admin/orders/openRow.directive";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        catchError((error) => this.handleError(error, this.authService))
      );
  }
  

  private handleError(err: HttpErrorResponse, authService: AuthService): Observable<any> {
      if (err.status === 401 || err.status === 403) {
          this.authService.logoutOnError();
          return of(err.message);
      }
      // handle your auth error or rethrow
      return Observable.throw(err);
  }
}

//Routes
const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AngularTokenService],
    children: [
      { path: "", redirectTo: "locations", pathMatch: "full" },
      {
        path: "locations",
        component: LocationsComponent,
      },
      {
        path: "orders",
        component: OrdersComponent,
      },
      {
        path: "new-order",
        component: NewOrderComponent,
        children: [
          { path: "", redirectTo: "calendar", pathMatch: "full" },
          {
            path: "calendar",
            component: TabDateComponent,
          },
          {
            path: "type",
            component: TabTypeComponent,
            canActivate: [StepTwoGuardService],
          },
          {
            path: "tickets",
            component: TabTicketsComponent,
            canActivate: [StepThreeGuardService],
          },
          {
            path: "payment",
            component: TabPaymentComponent,
            canActivate: [StepFourGuardService],
          },
          {
            path: "checkout",
            component: CheckoutComponent,
            canActivate: [CheckoutGuardService],
          },
        ],
      },
    ],
  },
  { path: "**", redirectTo: "login" },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    AdminComponent,
    OrdersComponent,
    LocationsComponent,
    NewOrderComponent,
    TabTypeComponent,
    TabDateComponent,
    TabsLinksComponent,
    TabTicketsComponent,
    TabPaymentComponent,
    CheckoutComponent,
    OpenRowDirective
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MyDatePickerModule,
    AngularTokenModule.forRoot({
      apiBase: environment.API,
      signInPath: "auth/login",
      registerAccountPath: "auth/login",
      signOutPath: "auth/logout",
      signOutFailedValidate: true
    }),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: "right",
          distance: 12,
        },
        vertical: {
          position: "top",
          distance: 20,
          gap: 10,
        },
      },
    }),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "ok",
      popoverMessage: "Sei sicuro do voler eliminare questa prenotazione?",
      placement: "top",
      cancelButtonType: "cancel",
      cancelText: "Annulla",
      confirmText: "Conferma",
    }),
    NgxPaginationModule
  ],
  providers: [
    AngularTokenModule,
    StepTwoGuardService,
    StepThreeGuardService,
    StepFourGuardService,
    CheckoutGuardService,
    {
      provide: FormDataService,
      useClass: FormDataService,
    },
    {
      provide: LOCALE_ID,
      useValue: "it-IT",
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router:Router, authService: AuthService) {
        return new AuthInterceptor(router, authService)
      },
      multi: true,
      deps: [Router, AuthService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
