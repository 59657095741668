import {Injectable} from '@angular/core';
import {AngularTokenService} from "angular-token";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private tokenService: AngularTokenService,
    private router: Router,
  ) {
  }

  isAuth() {
    return this.tokenService.userSignedIn();
  }

  logoutOnError(){
    this.tokenService.signOut().subscribe(
      res => {
        this.router.navigateByUrl('/');
      },
      error => {
        this.router.navigateByUrl('/');
      }
    );
  }

  redirectAuth() {
    if (this.isAuth()) {
      this.router.navigateByUrl('/admin');
    } else {
      this.router.navigateByUrl('/')
    }
    return;
  }
}
