import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularTokenService } from "angular-token";
import { AuthService } from "../services/auth.service";
import { NotifierService } from "angular-notifier";
import { error } from "@angular/compiler/src/util";
import { Router } from "@angular/router";
import { SharedService } from "../services/shared.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild("loginForm") loginForm;

  loginData: any = {};

  isLoading: boolean = false;

  private notifier: NotifierService;

  constructor(
    private tokenService: AngularTokenService,
    private authService: AuthService,
    private sharedService: SharedService,
    private notifierService: NotifierService,
    private router: Router
  ) {
    this.authService.redirectAuth();
  }

  ngOnInit() {
    this.notifier = this.notifierService;
  }

  signIn() {
    this.isLoading = true;

    let data = {
      login: this.loginData.email,
      password: this.loginData.password,
    };

    this.tokenService.signIn(data).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.body.user.locations.length < 2) {
          this.sharedService.setLocationUrl(res.body.user.location);
          localStorage.setItem(
            "location",
            JSON.stringify(res.body.user.location)
          );
        }
        this.router.navigateByUrl("/admin");
        this.loginForm.resetForm();
      },
      (error) => {
        this.isLoading = false;
        this.loginForm.resetForm();
        this.notifier.notify(
          "error",
          "Credenziali non valide, controlla i dati inseriti"
        );
      },
      () =>{
        this.isLoading = false;
        this.loginForm.resetForm();
      }
    );
  }
}
