import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { AngularTokenService } from "angular-token";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { SharedService } from "../services/shared.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  private notifier: NotifierService;

  headerNewOrderText: string = "Nuova prenotazione";
  headerPgTitle: string = "Elenco prenotazioni";

  isNewOrder: boolean = false;

  constructor(
    private tokenService: AngularTokenService,
    public authService: AuthService,
    public sharedService: SharedService,
    private notifierService: NotifierService,
    private router: Router
  ) {}

  ngOnInit() {
    this.notifier = this.notifierService;
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      let loc = JSON.parse(localStorage.getItem("location"));
      this.sharedService.setLocationUrl(loc)
    }
  }


  changeRoutesHeaderText() {
    this.headerNewOrderText = !this.isNewOrder
      ? "Elenco prenotazioni"
      : "Nuova prenotazione";
    this.headerPgTitle = this.isNewOrder
      ? "Elenco prenotazioni"
      : "Nuova prenotazione";
    this.router.navigateByUrl(!this.isNewOrder ? "admin/new-order" : "admin");
    this.isNewOrder = !this.isNewOrder;
  }

  private resetHeaderText() {
    if (this.isNewOrder) {
      this.isNewOrder = false;
      this.headerNewOrderText = "Nuova prenotazione";
      this.headerPgTitle = "Elenco prenotazioni";
    }
  }

  signOut() {
    this.tokenService.signOut().subscribe(
      (res) => {
        localStorage.removeItem("location");
        this.router.navigateByUrl("/");
        this.resetHeaderText();
      },
      (error) => {
        this.notifier.notify("error", "Si è verificato un errore!");
        this.router.navigateByUrl("/");
        this.resetHeaderText();
      }
    );
  }

  isSelectLocationView(): boolean {
    return window.location.href.split("/").slice(-1)[0] === "locations";
  }

  ngOnDestroy(){
    window.removeEventListener("storage", this.storageEventListener.bind(this));
}
}
