import {Component, OnInit} from '@angular/core';
import {FormDataService} from "../data/form-data.service";
import {ClientService} from "../../../services/client.service";
import {ActivatedRoute, Router} from "@angular/router";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tab-tickets',
  templateUrl: './tab-tickets.component.html',
  styleUrls: ['./tab-tickets.component.scss']
})
export class TabTicketsComponent implements OnInit {

  isLoading: boolean = true;

  apiData: any = {date: '', course: ''};

  ticketsArrModified: Array<any> = [];

  cartItems: any;
  currentLocation: {};
  private onSubject = new Subject<{ key: string, value: any }>();

  constructor(
    private formDataService: FormDataService,
    private clientService: ClientService,
    private router: Router,
    private _route: ActivatedRoute,
  ) {
    this.apiData = {
      date: this.formDataService.getSelectedDate().selectedDate,
      course: this.formDataService.getCourseType().course,
    };
  }

  ngOnInit() {
    //set active tab
    this.formDataService.setActiveStep('step_3', true);

    //get cart items
    this.cartItems = this.formDataService.getCart();

    //get api Data
    this.getTickets();

    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  private getTickets(){
    this.clientService.getTickets(this.apiData).subscribe(data => {

      //get tickets from API
      this.ticketsArrModified = this.formDataService.setTicketStructure(data);
      this.currentLocation = data.location;
      localStorage.setItem("location", JSON.stringify(this.currentLocation))
      this.isLoading = false;

    });
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      let loc = JSON.parse(localStorage.getItem("location"));
      if(loc.id != this.currentLocation["id"]){
        this.router.navigate(['/admin/new-order/calendar']);
      }
    }
  }


  decrementQty(ticket, type, ticketIndex, typeIndex, event) {

    const ticketRef = this.ticketsArrModified[ticketIndex],
      typeRef = ticketRef.types[typeIndex];


    //get check if qty between range
    let currentQty = parseInt(typeRef.modelVal);
    if (currentQty <= 0) {
      return;
    }

    //update qty of type
    typeRef.modelVal = currentQty - 1;

    //decrement total qty of ticket
    let currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty - 1;

    //Decrement TOTAL Ticket
    let currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal - parseFloat(typeRef.price);

    //check ticket total qty status
    let ticketTotalQty = parseInt(ticketRef.calcData.totalQty);
    ticketRef.calcData.available = ticketTotalQty > 0;

    //decrement ticket
    let cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelName: 'input__' + type.id,
      modelVal: typeRef.modelVal
    };

    this.formDataService.decrementTicketFromCart(ticket.id, cartType);

  }

  incrementQty(ticket, type, ticketIndex, typeIndex, event) {

    const ticketRef = this.ticketsArrModified[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    //check if the total qty is not extending the tickets available
    if (parseInt(ticketRef.calcData.totalQty) >= ticket.available_tickets) {
      return;
    }

    //get check if qty between range
    let currentQty = parseInt(typeRef.modelVal);
    if (currentQty >= ticket.available_tickets) {
      return;
    }

    //update qty
    typeRef.modelVal = currentQty + 1;

    //increment total qty of ticket
    let currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty + 1;

    //Increment TOTAL Ticket
    let currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal + parseFloat(typeRef.price);

    //check ticket total qty status
    let ticketTotalQty = parseInt(ticketRef.calcData.totalQty);

    ticketRef.calcData.available = ticketTotalQty > 0;

    //set / increment item to Cart[]
    let cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelName: 'input__' + type.id,
      modelVal: typeRef.modelVal
    };

    let cartTicket = {
      id: ticket.id,
      created_at: ticket.created_at,
      updated_at: ticket.updated_at,
      date: ticket.date,
      start_at: ticket.start_at,
      end_at: ticket.end_at,
      available_tickets: ticket.available_tickets,
      calcData: ticketRef.calcData,
      types: [cartType]
    };

    this.formDataService.incrementTicketToCart(cartTicket, cartType);

  }

  toggleElement(event) {
    let targetParent = event.currentTarget.parentElement;
    targetParent.classList.toggle("open");
    targetParent.children[1].classList.toggle("show");
  }

  checkInput(ele, ticketIndex, typeIndex) {

    let currentValue = parseInt(ele.value),
      modelValue = this.ticketsArrModified[ticketIndex].available_tickets;

    if (currentValue < 0 || currentValue > parseInt(modelValue)) {
      this.ticketsArrModified[ticketIndex].types[typeIndex].modelVal = 0;
    }
  }

  handleDisabledTicket(ticket){
    let current_date = this.getCurrentDate();
    let current_hour = this.getCurrentHour();
    if(  ticket.available_tickets <= 0 || (ticket.date == current_date && ticket.start_at < current_hour)){
      return true;
    }
    return false;
  }

  getCurrentDate(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }

  getCurrentHour(){
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes();
    return time;
  }

  goToNext() {
    if (this.cartItems.length > 0) {
      this.router.navigate(['/admin/new-order/payment']);
    }
  }

  ngOnDestroy(){
    window.removeEventListener("storage", this.storageEventListener.bind(this));
  }
}
