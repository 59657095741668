import { Component, OnInit } from "@angular/core";
import { ClientService } from "../../../services/client.service";
import { FormDataService } from "../data/form-data.service";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-tab-payment",
  templateUrl: "./tab-payment.component.html",
  styleUrls: ["./tab-payment.component.scss"],
})
export class TabPaymentComponent implements OnInit {
  countriesList: Array<any> = new Array<any>();

  paymentList: Array<any> = new Array<any>();

  invoiceList: Array<any> = new Array<any>();

  personalInfo: any = {};

  cartItems: Array<any> = [];

  cartTotal: any = 0;

  stepsData: any;

  isLoading: boolean = false;

  requiredCountryID: boolean = true;

  italyCountryID: any = 88; //Italy C_ID

  user_confirm_email_invalid:boolean = false;
  user_email_invalid: boolean = false;
  email_regex: any;
  payment_prefix: string;
  currentLocation: object;
  validateForm: boolean = true;

  constructor(
    private clientService: ClientService,
    private formDataService: FormDataService,
    private notifierService: NotifierService,
    private router: Router
  ) {
    this.paymentList = [
      {
        id: "to_pay",
        name: "Da pagare",
      },
      {
        id: "payed",
        name: "Pagato",
      },
      {
        id: "free",
        name: "Gratuito",
      },
    ];

    this.invoiceList = [
      {
        id: false,
        name: "Non richiesta",
      },
      {
        id: true,
        name: "Richiesta",
      },
    ];
    this.email_regex = /^(?!_)\w+([\.-]?\w+)*@(?!_)\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
  }

  ngOnInit() {
    //Set active tab
    this.formDataService.setActiveStep("step_4", true);

    //Get cart items
    this.cartItems = this.formDataService.getCart();

    //Check cart total
    this.cartTotal = this.formDataService.checkCartTotal();

    //Get steps info
    this.stepsData = {
      date: this.formDataService.getSelectedDate().selectedDate,
      course: this.formDataService.getCourseType().course,
    };

    //Get countries:API
    this.clientService.getCountries().subscribe((data) => {
      this.countriesList = data;
      this.personalInfo.country_id = this.italyCountryID;
    });

    //Get personal Data:FORM
   // this.personalInfo = this.formDataService.getPersonal();

    //check Cap field on step change
    if (
      this.personalInfo.country_id != this.italyCountryID &&
      this.personalInfo.country_id !== 0
    ) {
      //0 => disabled filed
      this.requiredCountryID = false;
    }

    //Set default selected values
    this.personalInfo.payment_type = "to_pay";
    this.personalInfo.invoice = false;

    //Get current location, if it is Padova disable validation form
    this.currentLocation = JSON.parse(localStorage.getItem("location"));
    if(this.currentLocation && this.currentLocation["payment_prefix"] == "PAD"){
      this.validateForm = false;
    }
  }

  countryChange(ev) {
    this.requiredCountryID = ev.target.value == this.italyCountryID;
  }

  decrementQty(ticket, type, ticketIndex, typeIndex, event) {
    let ticketData = this.formDataService.getTicketStructure();

    const ticketRef = this.cartItems[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    //get check if qty between range
    let currentQty = parseInt(typeRef.modelVal);
    if (currentQty <= 0) {
      return;
    }

    //update qty of type
    typeRef.modelVal = currentQty - 1;

    //decrement total qty of ticket
    let currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty - 1;

    //Decrement TOTAL Ticket
    let currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal - parseFloat(typeRef.price);

    //check ticket total qty status
    let ticketTotalQty = parseInt(ticketRef.calcData.totalQty);
    ticketRef.calcData.available = ticketTotalQty > 0;

    //decrement ticket
    let cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelVal: typeRef.modelVal,
    };
    this.formDataService.decrementTicketFromCart(ticket.id, cartType);

    //Get total
    this.cartTotal = this.formDataService.getCartTotal();

    //Sync tickets Val with cart Val
    this.formDataService.syncTicketCartQty(ticket, type, typeRef.modelVal);
  }

  incrementQty(ticket, type, ticketIndex, typeIndex, event) {
    const ticketRef = this.cartItems[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    //check if the total qty is not extending the tickets available
    if (parseInt(ticketRef.calcData.totalQty) >= ticket.available_tickets) {
      return;
    }

    //get check if qty between range
    let currentQty = parseInt(typeRef.modelVal);
    if (currentQty >= ticket.available_tickets) {
      return;
    }

    //update qty
    typeRef.modelVal = currentQty + 1;

    //increment total qty of ticket
    let currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty + 1;

    //Increment TOTAL Ticket
    let currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal + parseFloat(typeRef.price);

    //check ticket total qty status
    let ticketTotalQty = parseInt(ticketRef.calcData.totalQty);

    ticketRef.calcData.available = ticketTotalQty > 0;

    //set ticket
    let cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelVal: typeRef.modelVal,
    };
    let cartTicket = {
      id: ticket.id,
      created_at: ticket.created_at,
      updated_at: ticket.updated_at,
      date: ticket.date,
      start_at: ticket.start_at,
      end_at: ticket.end_at,
      available_tickets: ticket.available_tickets,
      calcData: ticketRef.calcData,
      types: [cartType],
    };
    this.formDataService.incrementTicketToCart(cartTicket, cartType);

    //Get total
    this.cartTotal = this.formDataService.getCartTotal();

    //Sync tickets Val with cart Val
    this.formDataService.syncTicketCartQty(ticket, type, typeRef.modelVal);
  }

  checkInput(ele, ticketIndex, typeIndex) {
    let cart = this.formDataService.getCart();

    let currentValue = parseInt(ele.value),
      modelValue = cart[ticketIndex].available_tickets;

    if (currentValue <= 0 || currentValue > parseInt(modelValue)) {
      // remove the type of this ticket
      this.formDataService.removeTypeTicket(
        false,
        false,
        ticketIndex,
        typeIndex
      );

      //update cartTotal ain view
      this.cartTotal = this.formDataService.getCartTotal();
    }
  }

  removeType(ticket, type, ticketInd, typeInd, event) {
    //remove types + ticket
    this.formDataService.removeTypeTicket(ticket, type, ticketInd, typeInd);

    //generate total price
    this.cartTotal = this.formDataService.getCartTotal();
  }

  payTickets(form) {
    this.isLoading = true;

    // if (!form.valid) {
    //   if (form.control.controls.user_name.invalid) {
    //     form.form.controls["user_name"].markAsDirty();
    //   }

    //   if (form.control.controls.user_email.invalid) {
    //     form.form.controls["user_email"].markAsDirty();
    //   }

    //   if (form.control.controls.user_phone.invalid) {
    //     form.form.controls["user_phone"].markAsDirty();
    //   }

    //   if (form.control.controls.country_id.value === 0) {
    //     form.form.controls["country_id"].setErrors("invalid", true);
    //   }

    //   if (form.control.controls.user_zip.invalid) {
    //     form.form.controls["user_zip"].markAsDirty();
    //   }

    //   this.isLoading = false;

    //   return;
    // }

    //Set personal Data:FORM
    this.formDataService.setPersonal(this.personalInfo);

    //Do checkout
    this.doCheckout();
  }

  private doCheckout() {
    let cartItems = this.formDataService.getCart();

    if (!cartItems || cartItems.length <= 0) {
      //TODO; error message

      this.notifierService.notify("error", "Error: Something went wrong!");

      this.router.navigate(["/admin/new-order/type"]);

      return;
    }

    let slots = [];

    cartItems.forEach((item) => {
      let _objTicket = {
        id: item.id,
        tickets: [],
      };

      let ticketItem = item;

      if (ticketItem.types && ticketItem.types.length > 0) {
        let typeArr = ticketItem.types;

        typeArr.forEach((type) => {
          let _objType = {
            quantity: type.modelVal,
            id: type.id,
          };

          _objTicket.tickets.push(_objType);
        });
      }

      //add ticket
      slots.push(_objTicket);
    });

    this.clientService
      .buyTickets(slots, this.personalInfo)
      .subscribe((response) => {

        this.isLoading = false;
        if (response && response.order &&  response.order.id) {

          //Store response
          this.formDataService.setOrderedItems(response.order);
          this.isLoading = false;

          //Redirect to last page
          this.afterCheckout();
        }
      },
      (error) => {

        this.isLoading = false;
      })
  }

  private afterCheckout() {
    this.personalInfo = {};
    //go to response view
    this.router.navigate(["/admin/new-order/checkout"]);

    return;
  }

  validateEmail(event){
    this.user_email_invalid = !event.target.value.match(this.email_regex);
  }


  validateConfirmEmail(event){
    this.user_confirm_email_invalid = !event.target.value.match(this.email_regex);
  }
}
