import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { SharedService } from "./shared.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ClientService {
  locationURL: string = "";

  private headers: HttpHeaders;

  private modalObj: Object = {
    title: "Si è verificato un errore",
    icon: "sad.png", // => assets/img/icons/ {name}
    btnText: "Go back",
    btnRedirect: "/",
  };

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.headers = new HttpHeaders().set("Content-Type", "application/json");
  }

  getCalendar(data): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;

    return this.http
      .post(
        environment.API +
          "ticket_office/" +
          this.locationURL +
          "/search/calendar",
        data,
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(<T>(error: any, result?: T) => {
          return of(result as T);
        })
      );
  }

  getCountries(): Observable<any> {
    return this.http.get(environment.API + "countries").pipe(
      map((res: any) => res.countries),
      catchError(<T>(error: any, result?: T) => {
        return of(result as T);
      })
    );
  }

  getCourses(req: string): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;

    let data = {
      date: req,
    };

    return this.http
      .post(
        environment.API +
          "ticket_office/" +
          this.locationURL +
          "/search/courses",
        data,
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => res),
        catchError(<T>(error: any, result?: T) => {
          return of(result as T);
        })
      );
  }

  getTickets(req): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;

    let data = {
      date: req.date,
      course_id: req.course.id,
    };

    return this.http
      .post(
        environment.API +
          "ticket_office/" +
          this.locationURL +
          "/search/tickets",
        data,
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => res),
        catchError(<T>(error: any, result?: T) => {
          return of(result as T);
        })
      );
  }

  buyTickets(slots, user): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;

    let data = {
      time_slots: slots,
      user_name: user.user_name,
      user_surname: user.user_surname,
      user_email: user.user_email,
      user_phone: user.user_phone,
      country_id: user.country_id,
      user_zip: user.user_zip,
      notes: user.notes,
      status: user.payment_type
    };

    return this.http
      .post(
        environment.API + "ticket_office/" + this.locationURL + "/orders",
        data,
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => res),
        catchError(<T>(error: any, result?: T) => {
          return of(result as T);
        })
      );
  }

  getLocations(): Observable<any> {
    return this.http.get(environment.API + "locations/select?online=true").pipe(
      map((res: any) => res.locations),
      catchError(<T>(error: any, result?: T) => {
        return of(result as T);
      })
    );
  }
}
