import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
  IMyCalendarViewChanged,
  IMyDateModel,
  IMyDpOptions,
} from "./my-date-picker/interfaces";
import { OrdersService } from "../../services/orders.service";
import { NotifierService } from "angular-notifier";
import * as moment from "moment";
import { SharedService } from "../../services/shared.service";
import { ClientService } from "src/app/services/client.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DebugRenderer2 } from "@angular/core/src/view/services";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit, OnDestroy {
  private notifier: NotifierService;

  public popoverTitle: string = "Elimina prenotazione per ";
  public locationsList: Array<any> = new Array<any>();
  public locationSelected: any;
  public typology: string = 'reservation';
  public openTicket: boolean = false;
  public filterCode: string;
  public open_orders: any;
  public open_codes: Array<string> = new Array<string>();
  page: number = 1;
  reloadCalendar: boolean = true;

  private date: Date = new Date();

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    minYear: this.date.getFullYear() - 1,
    maxYear: this.date.getFullYear() + 1,
    inline: true,
    monthLabels: {
      1: "Gennaio",
      2: "Febbraio",
      3: "Marzo",
      4: "Aprile",
      5: "Maggio",
      6: "Giugno",
      7: "Luglio",
      8: "Agosto",
      9: "Settembre",
      10: "Ottobre",
      11: "Novembre",
      12: "Dicembre",
    },
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDay() - 1},
    disableDays: [{ year: 0, month: 0, day: 0 }],
    highlightDates: [{ year: 0, month: 0, day: 0 }],
    selectorHeight: "auto",
    selectorWidth: "auto",
    sunHighlight: false,
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
  };

  localeIt: any = "it";

  filterData: any = {
    route: 1,
    name: "",
  };

  // order field boxes
  routesData: Array<any> = [];

  paymentStatusData = [
    {
      id: "to_pay",
      label: "Da pagare",
      selected: false,
    },
    {
      id: "payed",
      label: "Pagato",
      selected: false,
    },
    {
      id: "free",
      label: "Gratuito",
      selected: false,
    },
  ];

  invoiceStatusData = [
    {
      id: "1",
      label: "Non Richiesta",
      invoice_required: false,
    },
    {
      id: "2",
      label: "Richiesta",
      invoice_required: true,
    },
  ];

  reservations: Array<any> = [];

  filterReservations: Array<any> = [];

  isLoading: boolean = true;
  total: number;
  today: string;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  currentDate: string;

  constructor(
    private ordersService: OrdersService,
    private sharedService: SharedService,
    private notifierService: NotifierService,
    private clientService: ClientService,
    private ref: ChangeDetectorRef
  ) {
    this.notifier = this.notifierService;
  }

  ngOnInit() {
    this.locationSelected = localStorage.getItem("location")
    ? JSON.parse(localStorage.getItem("location")).id
    : null;

    this.clientService
      .getLocations()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.locationsList = data;
        let currentLocation = this.locationsList.find((l) => l.id === this.locationSelected);
        this.routesData = currentLocation.courses;
        this.filterData.route = "1";
      });

    this.today = moment().format("YYYY-MM-DD");
    this.currentDate = this.today;
    this.getOrdersByDate(this.today, this.filterData.name);
  }

  toggleElement(event) {
    let targetParent = event.currentTarget;

    targetParent.parentElement.classList.toggle("open");
    if(targetParent.parentElement.children[1]){
      targetParent.parentElement.children[1].classList.toggle("show");
    }
  }

  //Filter
  routeChange(ev) {
    let all = ev.target.value;

    if (all && all === "1") {
      this.reservations = this.filterReservations;
      return;
    }

    let value = ev.target.options[ev.target.selectedIndex].getAttribute("type");

    let reservations = this.filterReservations;

    this.reservations = reservations.filter((data) => {
      return data.course_name
        //.toLowerCase()
        // .replace(/ /g, "_")
        .includes(value);
    });
  }

  filterByName(){
    this.getOrdersByDate(this.currentDate, this.filterData.name);
  }

  // onSearchName() {
  //   this.filterByClient();
  // }

  // filterByClient() {
  //   let reservations = this.filterReservations;

  //   if (!this.filterData.name || this.filterData.name === "") {
  //     this.reservations = this.filterReservations;
  //     return;
  //   }

  //   if (!reservations || reservations.length <= 0) {
  //     this.filterData.name = "";
  //     return;
  //   }

  //   let filteredReservation = [];

  //   reservations.forEach((reservation) => {
  //     if (reservation.orders && reservation.orders.length > 0) {
  //       let orders = reservation.orders;

  //       let foundOrder = orders.filter((order) => {
  //         debugger;
  //         return (order.user_name
  //           .toLowerCase()
  //           .includes(this.filterData.name.toLowerCase().trim())) || (order.user_surname
  //             .toLowerCase()
  //             .includes(this.filterData.name.toLowerCase().trim() || (this.filterData.name
  //               .toLowerCase()
  //               .trim()
  //               .includes(order.user_surname.toLowerCase().trim()))
  //       });

  //       if (foundOrder && foundOrder.length > 0) {
  //         filteredReservation.push({
  //           course_name: reservation.course_name,
  //           start_at: reservation.start_at,
  //           availabe_tickets: reservation.availabe_tickets,
  //           booked_tickets: reservation.booked_tickets,
  //           orders: foundOrder,
  //         });
  //       }
  //     }
  //   });

  //   this.reservations = filteredReservation;
  // }


  //Order
  paymentStatusOnChange(orderId, ev) {
    let selectedValue = ev.target.value;

    let data = {
      status: selectedValue,
    };

    this.updateOrder(orderId, data, "Prenotazione aggiornata correttamente");
  }

  invoiceStatusOnChange(orderId, ev) {
    let selectedValue = ev.target.value;

    let data = {
      invoice_required: selectedValue,
    };

    this.updateOrder(orderId, data, "Prenotazione aggiornata correttamente");
  }

  checkInStatusOnChange(orderId, ev) {
    let selectedValue = ev.target.checked;

    let data = {
      check_in: selectedValue,
    };

    this.updateOrder(orderId, data, "Prenotazione aggiornata correttamente");
  }

  private updateOrder(orderId, data, msg: string = "Aggiornato con successo!") {
    this.ordersService
      .updateOrder(orderId, data)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.success) {
          this.notifier.notify("success", msg);
        }
      });
  }

  deleteOrder(orderId: string, reservationIndex, orderIndex) {
    this.ordersService
      .deleteOrder(orderId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.success) {
          this.reservations[reservationIndex].orders.splice(orderIndex, 1);
          this.notifier.notify(
            "success",
            `Prenotazione eliminata correttamente!`
          );
        }
      });
  }

  //Calendar
  onDateChanged(event: IMyDateModel) {
    this.isLoading = true;
    this.getOrdersByDate(event.formatted, this.filterData.name);
    this.currentDate = event.formatted;
    this.filterData.route = "1";
  }

  onCalendarViewChanged(event: IMyCalendarViewChanged) {
    this.getDatesApi(event.month.toString(), event.year.toString());
  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerInlineOptions));
  }

  private getOrdersByDate(date, name) {
    this.isLoading = true;
    this.ordersService
      .getOrders(date, name)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res.success) {
            this.reservations = this.filterReservations = res.data.time_slots;
          }
          //this.myDatePickerInlineOptions = this.getCopyOfOptions();
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        // }      if(name){
        //   var elements = document.getElementById("timeListAccordion");
        //   var list = elements.getElementsByClassName("list__item");
        //   for (var i = 0; i < list.length; i++) {
        //     this.toggleElement(list[i]);
        //   } 
        }
      );
  }

  getOrdersWithOpenTicket(page: number){
    this.page = page;
    this.isLoading = true;
    this.ordersService
      .getOrdersOpen(this.filterCode, this.page)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res.success) {
            this.open_orders = res.data.orders;
            this.page = res.data.pagination.current_page;
            this.total = res.data.total;
          }
          this.isLoading = false;
         // this.myDatePickerInlineOptions = this.getCopyOfOptions();
        },
        () => (this.isLoading = false)
      );
  }

  private getDatesApi(month, year) {
    let calendarPost = {
      month: month,
      year: year,
    };

    this.ordersService
      .getCalendar(calendarPost)
      // .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response.success) {
          this.disableDays(response.data.disabled_dates);
        }
      });
  }

  private disableDays(datesApi) {
    let copy = this.getCopyOfOptions();

    let newDates = [];
    let dates = [];
    datesApi.forEach((date) => {
      let parseDate = new Date(date);

      let obj = {
        year: parseDate.getFullYear(),
        month: parseDate.getMonth() + 1,
        day: parseDate.getDate(),
      };

      newDates.push(obj);
    });

    copy.disableDays = newDates;
    if(this.currentDate){
      let parseDate = new Date(this.currentDate);
      let obj = {
        year: parseDate.getFullYear(),
        month: parseDate.getMonth() + 1,
        day: parseDate.getDate(),
      };
      dates.push(obj);
      copy.highlightDates = dates;
    }
    this.myDatePickerInlineOptions = copy;
  }

  locationChange(locationId: string) {
    let location = this.locationsList.find((l) => l.id === locationId);
    let loc_id = this.locationsList.findIndex((l) => l.id === locationId);

    this.sharedService.setLocationUrl(
      this.locationsList[loc_id]
    );
    localStorage.setItem(
      "location",
      JSON.stringify(
        this.locationsList[loc_id]
      )
    );
    this.routesData = location.courses;
    this.filterData.route= "1";
    this.reloadCalendar = false;
    this.ref.detectChanges();
    if(this.typology != 'openTicket'){
      this.getOrdersByDate(this.today, this.filterData.name);
    }else{
      this.getOrdersWithOpenTicket(1);
    }
    this.reloadCalendar = true;
  }

  typologyChange(){
    if(this.typology == 'openTicket'){
      this.getOrdersWithOpenTicket(this.page);
    }
  }

  getTicketCodes(order_items){
    if(order_items.ticket_codes){
      order_items.codes = JSON.parse(order_items.ticket_codes);

    }
  }

  checkCode(order){
    var ticket_codes = order.order_items[0].ticket_codes;
    let data = {
      ticket_codes: ticket_codes
    };
    this.updateOrder(order.order_items[0].id, data, "Ordine aggiornato correttamente");
  }


  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
