import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AngularTokenService } from "angular-token";
import { NotifierService } from "angular-notifier";
import { AuthService } from "./auth.service";
import { SharedService } from "./shared.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  locationURL: string = "";

  private headers: HttpHeaders;

  private notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private tokenService: AngularTokenService,
    private authService: AuthService,
    private notifierService: NotifierService,
    private sharedService: SharedService
  ) {
    this.notifier = this.notifierService;
    this.headers = new HttpHeaders().set("Content-Type", "application/json");
  }

  getCalendar(data): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;

    return this.http
      .post(
        environment.API +
          "ticket_office/" +
          this.locationURL +
          "/search/calendar",
        data,
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          return of(result as T);
        })
      );
  }

  getOrders(date: string, name:string): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;
    var url = environment.API + "ticket_office/" + this.locationURL + "/orders?date=" + date;
    if(name){
      url += "&name=" + name;
    }
    return this.http
      .get(url)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          let msg = error.error.errors
            ? error.error.errors[0]
            : "Si è verificato un errore";
          this.notifier.notify("error", `Error: ${msg}!`);

          if (error.status == 500) {
            this.authService.logoutOnError();
          }

          return of(result as T);
        })
      );
  }

  getOrdersOpen(filter, page): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;
    let url = environment.API + "ticket_office/" + this.locationURL + "/orders/open?page="+ page + "&per_page=20";
    if(filter){
      url += "&name=" + filter;
    }

    return this.http
      .get(url)
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          let msg = error.error.errors
            ? error.error.errors[0]
            : "Si è verificato un errore";
          this.notifier.notify("error", `Error: ${msg}!`);

          if (error.status == 500) {
            this.authService.logoutOnError();
          }

          return of(result as T);
        })
      );
  }

  updateOrder(orderId: string, orderData: any): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;

    return this.http
      .put(
        environment.API +
          "ticket_office/" +
          this.locationURL +
          "/orders/" +
          orderId,
        orderData
      )
      .pipe(
        map((res) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          let msg = error.error.errors
            ? error.error.errors[0]
            : "Si è verificato un errore";
          this.notifier.notify("error", `Error: ${msg}!`);

          if (error.status == 500) {
            this.authService.logoutOnError();
          }

          return of(result as T);
        })
      );
  }

  deleteOrder(orderId: string): Observable<any> {
    this.locationURL = this.sharedService.getLocationUrl().permalink;

    return this.http
      .delete(
        environment.API +
          "ticket_office/" +
          this.locationURL +
          "/orders/" +
          orderId
      )
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          let msg = error.error.errors
            ? error.error.errors[0]
            : "Si è verificato un errore";
          this.notifier.notify("error", `Error: ${msg}!`);

          if (error.status == 500) {
            this.authService.logoutOnError();
          }

          return of(result as T);
        })
      );
  }
}
