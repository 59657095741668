import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  location: Object = {
    id: null,
    permalink: null,
    name: null
  };

  constructor() {
  }

  //Location URL
  setLocationUrl(location) {
    this.location = location;
  }

  getLocationUrl(): any {

    if (!this.location['permalink']) {
      return JSON.parse(localStorage.getItem('location'));
    }

    return this.location;
  }
}
