import {Component, OnInit} from '@angular/core';
import {FormDataService} from "../data/form-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ClientService} from "../../../services/client.service";

@Component({
  selector: 'app-tab-type',
  templateUrl: './tab-type.component.html',
  styleUrls: ['./tab-type.component.scss']
})
export class TabTypeComponent implements OnInit {

  isLoading: boolean = true;

  availableCourses: Array<any> = [];
  apiData: any = {selectedDate: ''};

  constructor(
    private formDataService: FormDataService,
    private clientService: ClientService,
    private router: Router,
    private _route: ActivatedRoute,
  ) {
    this.apiData = this.formDataService.getSelectedDate();

    //Clear cart
    this.formDataService.resetCart();
  }

  ngOnInit() {

    //remove active steps
    this.formDataService.setActiveStep('step_3', false);
    this.formDataService.setActiveStep('step_4', false);

    //get api Data
    this.clientService.getCourses(this.apiData.selectedDate).subscribe(data => {
      this.availableCourses = data.available_courses;
      this.isLoading = false;
    });

  }

  goToNext(course: any) {
    if (this.saveStepData(course)) {
      //ste active step 3 -> Guard
      this.formDataService.setActiveStep('step_3', true);
      this.router.navigate(['../tickets'], {relativeTo: this._route});
    }
  }

  private saveStepData(course: any): boolean {
    if (!course) {
      return false;
    }
    this.formDataService.setCourseType(course);
    return true;
  }

}
