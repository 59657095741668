import { Directive, ElementRef, Input } from '@angular/core';
import { OrdersComponent } from './orders.component';

@Directive({
  selector: '[openRow]'
})
export class OpenRowDirective {
  constructor(private el: ElementRef, private host:OrdersComponent) {
  if(host.filterData.name != '' && host.filterData.name != undefined){
    if(el.nativeElement.parentElement.children[1]){
      el.nativeElement.parentElement.classList.add("open");
      el.nativeElement.parentElement.children[1].classList.toggle("show");
    }
  }
 }
}