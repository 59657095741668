import {Injectable} from '@angular/core';
import {FormDataService} from "../data/form-data.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class StepFourGuardService {

  constructor(private formData: FormDataService, public router: Router) {
  }

  canActivate(): boolean {
    let cart = this.formData.getCart();
    if (!cart || cart.length <= 0) {
      this.router.navigate(['/admin/new-order/tickets']);
      return false;
    }
    return true;
  }
}
