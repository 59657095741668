import { Component, OnInit } from '@angular/core';
import { IMyCalendarViewChanged, IMyDateModel, IMyDpOptions } from "../../orders/my-date-picker/interfaces";
import { FormDataService } from "../data/form-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Calendar } from "../data/formData.model";
import { ClientService } from "../../../services/client.service";

@Component({
  selector: 'app-tab-date',
  templateUrl: './tab-date.component.html',
  styleUrls: ['./tab-date.component.scss']
})
export class TabDateComponent implements OnInit {

  private date: Date = new Date();

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    minYear: this.date.getFullYear(),
    // maxYear: this.date.getFullYear(),
    inline: true,
    monthLabels: {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre'
    },
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDay() - 1 },
    disableDays: [{ year: 0, month: 0, day: 0 }],
    highlightDates: [{ year: 0, month: 0, day: 0 }],
    selectorHeight: 'auto',
    selectorWidth: 'auto',
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
    sunHighlight: false,
  };
  localeIt: any = 'it';
  calendar: Calendar = {
    selectedDate: false, // moment().format("YYYY-MM-DD")
  };

  constructor(
    private formDataService: FormDataService,
    private clientService: ClientService,
    private router: Router,
    private _route: ActivatedRoute,
  ) {

    this.disableUntil();

    this.formDataService.resetCourse();
  }

  ngOnInit() {
    //rest steps
    this.formDataService.resetActiveSteps();

    //set active tab
    this.formDataService.setActiveStep('step_1', true);

    //Remove today as selected since it may be disabled
    /*this.calendar = {
      selectedDate: null, // moment().format("YYYY-MM-DD")
      policyAccepted: false
    };

    this.formDataService.setSelectedDate(this.calendar);*/

    //Get available dates API
    // @ usage Called from the onCalendarViewChanged()

  }

  goToNext(form: any) {

    if (this.saveStepData(form)) {
      this.formDataService.setActiveStep('step_1', true);
      this.formDataService.setActiveStep('step_2', true);
      this.router.navigate(['../type'], { relativeTo: this._route });
    }
  }

  onDateChanged(event: IMyDateModel) {
    this.calendar.selectedDate = event.formatted;
    this.formDataService.setSelectedDate(this.calendar);
  }

  onCalendarViewChanged(event: IMyCalendarViewChanged) {
    this.getDatesApi(event.month.toString(), event.year.toString());
  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerInlineOptions));
  }

  /**
   * Disable days till tomorrow
   */
  private disableUntil() {

    let d = new Date();

    d.setDate(d.getDate() - 1);

    let copy = this.getCopyOfOptions();

    copy.disableUntil = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };

    this.myDatePickerInlineOptions = copy;
  }

  private saveStepData(form: any): boolean {

    if (!form.valid) {
      return false;
    }

    this.formDataService.setSelectedDate(this.calendar);
    return true;
  }

  private getDatesApi(month, year) {

    let calendarPost = {
      month: month,
      year: year
    };

    this.clientService.getCalendar(calendarPost).subscribe(data => {
      this.disableDays(data.disabled_dates);
      this.reservedDates(data.ticket_office_opening);
    });

  }

  private disableDays(datesApi) {

    let copy = this.getCopyOfOptions();

    let newDates = [];

    datesApi.forEach(date => {

      let parseDate = new Date(date);

      let obj = {
        year: parseDate.getFullYear(),
        month: parseDate.getMonth() + 1,
        day: parseDate.getDate(),
      };

      newDates.push(obj);

    });

    copy.disableDays = newDates;
    this.myDatePickerInlineOptions = copy;
  }

  private reservedDates(datesApi) {

    let d = new Date();

    let copy = this.getCopyOfOptions();

    let dates = [];

    datesApi.forEach(date => {

      let parseDate = new Date(date);

      let obj = {
        year: parseDate.getFullYear(),
        month: parseDate.getMonth() + 1,
        day: parseDate.getDate(),
      };

      dates.push(obj);

    });

    copy.highlightDates = dates;

    this.myDatePickerInlineOptions = copy;
  }

}
