import {Injectable} from '@angular/core';
import {FormDataService} from "../data/form-data.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuardService {

  constructor(private formData: FormDataService, public router: Router) {
  }

  canActivate(): boolean {

    let stepsCompleted: boolean = this.formData.activeSteps['step_2'] = this.formData.activeSteps['step_3'] = this.formData.activeSteps['step_4'];

    if (!stepsCompleted) {
      this.router.navigate(['/admin/new-order/tickets']);
      return false;
    }
    return true;
  }
}
